import React from 'react';
import styled from 'styled-components';

import ProductWrapper from 'parts/product-columns/ProductWrapper';
import Link from 'components/Link';
import PriceAndBuyBtn from 'components/products/PriceAndBuyBtn';
import LazyImage from 'components/LazyImage';
import DefaultImage from 'images/placeholders/default-image-post.jpg';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const ImageLink = styled(Link)`
	display: block;
	width: 100%;
	margin-bottom: 30px;
	.lazy-image {
		position: relative;
		width: 100%;
		padding-top: 62.5%; /* 16:10 aspect ratio */
		overflow: hidden;
		.lazy-image__main {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: auto;
			height: auto;
			width: auto;
			max-height: 100%;
			max-width: 100%;
		}
	}
`;

const ReadMore = styled(Link)`
	margin-top: 20px;
`;

/**
 * Represents a product item
 * @param {object} product - The product properties
 * @param {string} headinglevel - The heading level for the product title
 * @param {...object} props - The properties for the component
 */
export default function ProductItem({
	product,
	headinglevel = 'h3',
	...props
}) {
	// Temporary stuff
	product = {
		...product,
		title: product?.title || product?.tittel,
		smallTitle: product?.smallTitle || product?.undertittel,
		desc: product?.desc || product?.kortBeskrivelse,
		price: product?.productPrice || product?.price || product?.pris,
		campaignPrice: product?.campaignPrice || product?.kampanjepris,
		paymentMethods: product?.paymentMethods,
		image:
			(product?.images?.length > 0 && product?.images[0]) ||
			product?.bilde,
	};

	const productTitle =
		product?.title && product?.smallTitle
			? `${product?.title} - ${product.smallTitle}`
			: product?.title;

	return (
		<ProductWrapper
			id={product?.id}
			className="contentfulinnholdprodukt"
			customBadge={product.badge}
			campaign={product?.campaignPrice}
			perrow={props?.perrow || 3}
			{...product}
			header={
				<>
					<ImageLink
						to={`/produkter/${product.slug}`}
						title={`Gå til ${productTitle}`}>
						{product?.image ? (
							<LazyImage
								{...product?.image}
								width={768}
								height={480}
								alt={
									product?.image?.description ||
									product?.image?.title ||
									productTitle
								}
							/>
						) : (
							<img src={DefaultImage} alt={productTitle} />
						)}
					</ImageLink>
					<TitleAndText
						stickTitle={product?.smallTitle}
						title={product?.title}
						headinglevel={headinglevel}
						settings={['Midtstilt tekst']}
						nested={true}
					/>
				</>
			}>
			<PriceAndBuyBtn
				product={product}
				variant="simple"
				stretch={false}
			/>

			<ReadMore
				to={`/produkter/${product.slug}`}
				aria-label={`Les mer om ${productTitle}`}
				data-cy="read-more-btn"
				style={{ margin: '20px auto 0' }}>
				Les mer om produktet
			</ReadMore>
		</ProductWrapper>
	);
}
